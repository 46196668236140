import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-main',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  isLoading = false;

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe((bool) => {
      this.isLoading = bool;
    });
  }
}
