import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

export abstract class BaseCalls {
  protected baseUrl = '/api/v3';

  /**
   * Sets the secure flag if we are using a https session to authenticate
   * Interceptor adds header -> shared/handlers/interceptor
   * @param httpClient
   */
  constructor(protected httpClient: HttpClient) {}

  public call(...args: any[]) {
    let url = this.baseUrl;

    args.forEach(function (element) {
      url += '/' + element;
    });

    let ignore = false;

    if (url.includes('get_bulk_thumbnails')) {
      ignore = true;
    }

    if (url.includes('get_current_image')) {
      ignore = true;
    }

    return this.doCall(url, ignore);
  }

  protected doPostCall(url: any, data: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'), //Probably redundant, interceptor adds this header, keeping it for now in case I'm missing something
    });
    let options = { headers: headers };
    return this.httpClient
      .post(url, data, options)
      .pipe(map((response: Object) => <any>response));
  }

  protected doPutCall(url: any, body: any) {
    return this.httpClient
      .put(url, body)
      .pipe(map((response: Object) => <any>response));
  }

  protected doDeleteCall(url: any) {
    return this.httpClient
      .delete(url)
      .pipe(map((response: Object) => <any>response));
  }

  protected doPostCall_legacy(url: any, data: any, ignore = false) {
    if (ignore) {
      return this.httpClient
        .post(url, data, {
          headers: { ignoreLoadingBar: '' },
        })
        .pipe(map((response: Object) => <any>response));
    }

    return this.httpClient
      .post(url, data)
      .pipe(map((response: Object) => <any>response));
  }

  protected doCall(url: any, ignore = false) {
    if (ignore) {
      return this.httpClient
        .get(url, {
          headers: { ignoreLoadingBar: '' },
        })
        .pipe(map((response: Object) => <any>response));
    }

    return this.httpClient
      .get(url)
      .pipe(map((response: Object) => <any>response));
  }
}
