import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {SetpasswordComponent} from './setpassword/setpassword.component';
import {OtpComponent} from "./otp/otp.component";
import {SharedDownloadComponent} from "./shared-download/shared-download.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {path: 'login', component: LoginComponent},
      {path: 'password-reset', component: PasswordResetComponent},
      {path: 'reset-password/:token', component: SetpasswordComponent},
      {path: 'otp', component: OtpComponent},
      {path: 'shared-download/:token', component: SharedDownloadComponent},
      {path: '**', redirectTo: 'login'},
    ]),
  ],
  exports: [RouterModule],
})
export class PublicRoutingModule {
}
