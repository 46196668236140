import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SwitchOrgService } from './switch-org.service';

@Component({
  selector: 'app-switch-org-dialog',
  templateUrl: './switch-org-dialog.component.html',
  styleUrls: ['./switch-org-dialog.component.scss'],
})
export class SwitchOrgDialogComponent {
  formGroup = new FormGroup({
    selectedOrgUser: new FormControl([null, Validators.required]),
  });

  orgUsers = [];
  selectedOrgUser: any | null = null;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private switchOrgService: SwitchOrgService
  ) {}

  ngOnInit() {
    this.switchOrgService.getOrganizationUsers().subscribe((data: any) => {
      this.orgUsers = data;
    });
  }

  doSwitchOrg() {
    //validate the form
    if (this.formGroup.invalid) {
      return;
    }
    this.switchOrgService.switchOrganization(
      this.selectedOrgUser.organization_uuid,
      null
    );
  }
}
