import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PublicRoutingModule} from './public-routing.module';
import {LoginComponent} from './login/login.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {FooterComponent} from './shared/footer/footer.component';
import {HeaderComponent} from './shared/header/header.component';
import {SharedModule} from 'primeng/api';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SetpasswordComponent} from './setpassword/setpassword.component';
import {OtpComponent} from "./otp/otp.component";
import {SharedDownloadComponent} from './shared-download/shared-download.component';

@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    FooterComponent,
    HeaderComponent,
    SetpasswordComponent,
    OtpComponent,
    SharedDownloadComponent
  ],
  exports: [HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class PublicModule {}
