<p-card [ngClass]="'title-center'" header="Password reset">
  <div (keydown.enter)="requestPassword()" [formGroup]="email">
    <span class="p-float-label input">
      <input
        [email]="true"
        [value]="email"
        formControlName="email"
        id="email-input"
        pInputText
      />
      <label for="email-input" i18n>Email address</label>
    </span>
    <div class="text-center">
      <button (click)="requestPassword()" class="requestButton" i18n pButton>Request password reset</button>
    </div>
    <a i18n routerLink="/login">Back to login</a>
  </div>
</p-card>
