import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {ToastService} from "../../shared/services/toast.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [AuthService],
})
export class PasswordResetComponent {
  email = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
  ) {
  }

  requestPassword() {
    this.email.markAllAsTouched();

    if (this.email.invalid) return;

    let email = this.email.get('email')!.value as string;

    this.authService.postPasswordReset(email.toLowerCase()).subscribe(() => {
      this.toastService.addToast('Password reset requested, please check your mailbox.',
        'success', 'pi pi-info-circle');
      this.router.navigate(['/login']);
    });
  }
}
