import {MessageService} from "primeng/api";
import {Injectable} from "@angular/core";

@Injectable()
export class ToastService {
  constructor(private messageService: MessageService) {
  }

  addToast(message: string, severity: string, icon: string) {
    this.messageService.clear(severity)
    this.messageService.add({key: severity, summary: message, severity: severity, icon: icon})
  }
}
