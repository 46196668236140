<div class="sidebar">
  <div class="logo">
    <a routerLink="/">
      <img
        alt="vCap logo"
        src="assets/img/vCap_logo_Outline_wit_text_wit.svg"
      />
    </a>
  </div>
  <hr/>
  <ng-scrollbar class="my-scrollbar">
    <p-panelMenu
      [model]="menuItems"
      [multiple]="false"
      [ngClass]="'nav-sidebar'"
    ></p-panelMenu>
  </ng-scrollbar>
</div>
