import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { MenubarModule } from 'primeng/menubar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImpersonateDialogComponent } from './impersonate-dialog/impersonate-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { SwitchOrgDialogComponent } from './switch-org-dialog/switch-org-dialog.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ImpersonateDialogComponent,
    SwitchOrgDialogComponent,
  ],
  exports: [NavbarComponent, ImpersonateDialogComponent],
  imports: [
    CommonModule,
    MenubarModule,
    SlideMenuModule,
    ButtonModule,
    RippleModule,
    OverlayPanelModule,
    MenuModule,
    TieredMenuModule,
    DynamicDialogModule,
    FormsModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    ReactiveFormsModule,
  ],
})
export class NavbarModule {}
