/**
 * @license
 * Copyright View and Integrate BVBA. All Rights Reserved.
 *
 * @author
 * Jorne Marx <jorne.marx@viewandintegrate.be>
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class OrgAdminGuardService  {
  constructor(public router: Router, public auth: AuthService) {}

  canActivate(): Observable<boolean> | boolean {
    //check here if user is orgadmin
    return this.auth.getUserInfo().pipe(
      map((userinfo: any) => {
        if (
          userinfo.type === 'organization_admin' ||
          userinfo.type === 'superadmin'
        ) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}
