/**
 * @license
 * Copyright View and Integrate BVBA. All Rights Reserved.
 *
 * @author
 * Jorne Marx <jorne.marx@viewandintegrate.be>
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class FeatureGuardService  {
  constructor(public router: Router, public auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    //check if the clicked feature is enabled for the user
    let feature_name = route.data['title'].toLowerCase().replace(' ', '_');
    let allowed = false;
    return this.auth.getAllowedFeatures().pipe(
      map((features: any) => {
        //Loop over the features and check if the name of the clicked feature is in the list
        features.forEach((feature: any) => {
          if (feature.feature_name == feature_name) {
            allowed = true;
          }
        });
        if (!allowed) {
          this.router.navigate(['/']);
        }
        return allowed;
      })
    );
  }
}
