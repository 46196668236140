import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ToastService} from "../../shared/services/toast.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private _activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }

    //check if the landing-email cookie is set, if so, set the email field to the value of the cookie
    if (this.cookieService.check('landing-email')) {
      this.loginForm.get('email')?.setValue(this.cookieService.get('landing-email'));
    }
  }

  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.authService.login(
      this.loginForm.get('email')?.value?.toLowerCase(),
      this.loginForm.get('password')?.value,
      () =>
        this.toastService.addToast('Error during login, check details and try again',
          'error', 'pi pi-exclamation-triangle')
    );
  }
}
