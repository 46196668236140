<div class="navbar">
  <div class="navbar-left">
    <div class="sidebar-button">
      <button
        (click)="menu.toggle($event)"
        class="p-button-sm p-button-rounded"
        i18n-pTooltip
        icon="pi pi-ellipsis-v"
        pButton
        pRipple
        pTooltip="Menu"
      ></button>
      <p-tieredMenu #menu [model]="menuItems" [popup]="true"></p-tieredMenu>
    </div>
    <div class="navbar-header">
      {{ title }}
    </div>
  </div>
  <div class="navbar-right">
    <div class="user-menu">
      <span class="org-header">
        <p><i class="pi pi-briefcase"></i> {{ userInfo?.current_org_name }}</p>
      </span>
      <div class="dropdown">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn dropdown-toggle user-header"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
          pButton
        >
          <i class="pi pi-user"></i>
          <p *ngIf="!oldUsername">{{ userInfo?.name }}</p>
          <p *ngIf="oldUsername">{{ oldUsername }}</p>
        </button>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
          <a class="dropdown-item" i18n routerLink="profile">
            <i class="pi pi-wrench"></i>
            Profile settings
          </a>
          <a
            (click)="show_org_switch()"
            *ngIf="!oldToken && organization_users.length > 1"
            class="dropdown-item"
            i18n
          >
            <i class="pi pi-arrow-right-arrow-left"></i>
            Switch organization
          </a>
          <a
            (click)="show_impersonate_switch()"
            *ngIf="!oldToken && userInfo?.allow_impersonate"
            class="dropdown-item"
            i18n
          >
            <i class="pi pi-id-card"></i>
            Impersonate
          </a>
          <a
            (click)="endImpersonate()"
            *ngIf="oldToken"
            class="dropdown-item"
            i18n
          >
            <i class="pi pi-times-circle"></i>
            End impersonation
          </a>
          <a
            *ngIf="userInfo?.allow_impersonate"
            class="dropdown-item"
            routerLink="/superadmin"
          >
            <i class="pi pi-user"></i>
            Superadmin panel
          </a>
          <!--          <a (click)="aboutPopup()" class="dropdown-item" i18n>-->
          <!--            <i class="pi pi-info-circle"></i>-->
          <!--            About vCap-->
          <!--          </a>-->
          <a (click)="logOut()" class="dropdown-item" i18n>
            <i class="pi pi-sign-out"></i>
            Log out
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
