<ng-form [formGroup]="formGroup">
  <div class="org-usr-container">
    <p i18n>Select an organization and a user to impersonate.</p>
    <div class="org-user">
      <div class="">
        <span class="p-float-label">
          <p-dropdown
            (onChange)="orgChanged()"
            [options]="organizations"
            [required]="true"
            formControlName="selectedOrg"
            i18n-placeholder
            id="org-input"
            name="organization"
            optionLabel="name"
            optionValue="uuid"
            placeholder="Choose"
            [filter]="true"
            [filterBy]="'name'"
            appendTo="body"
          ></p-dropdown>
          <label for="org-input" i18n>Organization</label>
        </span>
      </div>
      <div class="">
        <span class="p-float-label">
          <p-dropdown
            [options]="orgUsers"
            [required]="true"
            formControlName="selectedOrgUser"
            id="usr-input"
            name="user"
            optionLabel="user.name"
            [filter]="true"
            [filterBy]="'user.name'"
            appendTo="body"
          ></p-dropdown>
          <label for="usr-input" i18n>User</label>
        </span>
      </div>
    </div>
  </div>
  <div class="divider-container">
    <p-divider></p-divider>
  </div>
  <div class="reason-container">
    <p i18n>What is the reason for impersonating this user?</p>
    <span class="p-float-label">
      <input
        [minlength]="10"
        [required]="true"
        formControlName="reason"
        id="reason-input"
        name="reason"
        pInputText
        type="text"
      />
      <label for="reason-input" i18n>Reason</label>
    </span>
  </div>
  <div class="submit-button">
    <p-button
      (click)="doImpersonate()"
      [disabled]="formGroup.invalid"
      class="next-button"
      i18n
      type="submit"
    >
      Impersonate
    </p-button>
  </div>
</ng-form>
