import {Component, HostListener, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-shared-download',
  templateUrl: './shared-download.component.html',
  styleUrl: './shared-download.component.scss'
})
export class SharedDownloadComponent implements OnInit {
  videosrc: any;

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    //https://s3.dev.vcap.be/vni-3f18/9/b/3f189b80-0fe9-40b2-bbc8-30c92af2fc1a.mp4?AWSAccessKeyId=ZKpXiSYCiy0u4J6H&Signature=L%2B%2FO764obTjSq0WODl96kkuwu3U%3D&Expires=1716472017
    this.activatedRoute.params.subscribe((params) => {
      if (!params['token'])
        return;

      let token = this.router.url.split('/')[2];
      let uuidURL = token.substring(0, 8) + '/' + token.substring(8, 9) + '/' + token.substring(9, 10) + '/' + token.substring(10)
      this.videosrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://s3.dev.vcap.be/" + uuidURL);
    });
  }

  @HostListener('contextmenu', ['$event'])
  onClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
