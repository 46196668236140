import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCalls } from 'src/objects/BaseCalls';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ImpersonateService extends BaseCalls {
  constructor(httpClient: HttpClient, private router: Router) {
    super(httpClient);
  }

  public getOrganizations(OrgName?: string) {
    let url = this.baseUrl + '/admin/organizations';
    if (OrgName) {
      url += '?name=' + OrgName;
    }
    return this.doCall(url);
  }

  public getUsersForOrganization(organizationUUID: string, UserName?: string) {
    let url = this.baseUrl + '/admin/organization_users?organization_uuid=' + organizationUUID;
    if (UserName) {
      url += '&name=' + UserName;
    }
    return this.doCall(url);
  }

  public impersonateUser(
    org_user_uuid: string,
    reason: string,
    error_func: any,
    username: string
  ) {
    this.doPostCall(
      this.baseUrl +
        '/login/do-impersonate?organization_user_uuid=' +
        org_user_uuid +
        '&reason=' +
        reason,
      null
    ).subscribe((response: any) => {
      if (response.state === 'error') {
        return error_func(response.message);
      }

      let old_token = localStorage.getItem('token');
      let old_username = localStorage.getItem('username');

      localStorage.clear();
      localStorage.setItem('token', response.access_token);
      localStorage.setItem('username', username);
      localStorage.setItem('old_token', old_token ?? '');
      localStorage.setItem('old_username', old_username ?? '');

      this.router.navigate(['/']);
      window.location.reload();
    });
  }
}
