<ng-form [formGroup]="formGroup">
  <div class="org-usr-container">
    <p i18n>Select the organization to switch to.</p>
    <div class="row">
      <div class="col-4">
        <span class="p-float-label">
          <p-dropdown
            [(ngModel)]="selectedOrgUser"
            [options]="orgUsers"
            [required]="true"
            appendTo="body"
            formControlName="selectedOrgUser"
            id="org-usr-input"
            name="org-user"
            optionLabel="organization.name"
          ></p-dropdown>
          <label for="org-usr-input" i18n>Organization</label>
        </span>
      </div>
    </div>
  </div>
  <div class="submit-button">
    <p-button
      (click)="doSwitchOrg()"
      [disabled]="formGroup.invalid"
      class="next-button"
      i18n
      type="submit"
    >Switch
    </p-button>
  </div>
</ng-form>
