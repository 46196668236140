import {Component} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {ToastService} from "../../shared/services/toast.service";

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss'],
  providers: [AuthService],
})
export class SetpasswordComponent {
  password = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    repeatPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      this.matchValidator('password'),
    ]),
  });
  token: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    activatedRoute.params.subscribe((res) => {
      if (!res['token']) router.navigate(['/login']);
      this.token = res['token'];
    });
  }

  savePassword() {
    this.password.markAllAsTouched();
    if (this.password.invalid) return;

    let password = this.password.get('password')?.value as string;

    this.authService.postNewPassword(password, this.token).subscribe(() => {
      this.toastService.addToast('Password reset successful', 'success', 'pi pi-info-circle');
      this.router.navigate(['/login']);
    });
  }

  matchValidator(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : {matching: true};
    };
  }
}
