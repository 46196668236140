/**
 * @license
 * Copyright View and Integrate BVBA. All Rights Reserved.
 *
 * @author
 * Eric Lembregts <eric@viewandintegrate.be>
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService  {
  constructor(public router: Router, public auth: AuthService) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
