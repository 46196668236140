<p-card [ngClass]="'title-center'">
  <div class="pb-4">
    <img
      alt="vCap logo"
      src="assets/img/vCap_logo_Outline_wit_text_zwart.svg"
      width="75%"
    />
  </div>
  <div (keydown.enter)="login()" [formGroup]="loginForm">
    <span class="p-float-label">
      <input
        [email]="true"
        class="input"
        formControlName="email"
        id="email-input"
        pInputText
      />
      <label for="email-input" i18n>Email address</label>
    </span>
    <span class="p-float-label">
      <p-password
        [feedback]="false"
        [toggleMask]="true"
        formControlName="password"
        id="password-input"
      >
      </p-password>
      <label for="password-input" i18n>Password</label>
    </span>
    <div class="text-center loginButton-container">
      <a (click)="login()" class="login-button" i18n pButton>Login</a>
    </div>
    <div>
      <a i18n routerLink="/password-reset">Password reset</a>
    </div>
  </div>
</p-card>
