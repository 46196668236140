import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuItems: any;
  allowedFeatures: string[] = [];
  userInfo: any;

  //Vorig actief menu
  private prevTarget: any;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.getUserInfo().subscribe((response: any) => {
      this.userInfo = response;

      this.authService.getAllowedFeatures().subscribe((response: any) => {
        response.forEach((item: any) => {
          this.allowedFeatures.push(item.feature_name);
        });
        this.menuItems = [
          {
            routerLink: 'quickview',
            label: 'Quickview',
            icon: 'pi pi-forward',
            visible: this.isFeatureAllowed('quickview'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            routerLinkActiveOptions: {exact: true},
          },
          {
            routerLink: 'liveview',
            label: 'Liveview',
            icon: 'pi pi-play',
            visible: this.isFeatureAllowed('liveview'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            routerLinkActiveOptions: {exact: true},
          },
          {
            routerLink: 'power-dashboard',
            label: 'Power dashboard',
            visible: this.isFeatureAllowed('power_dashboard'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            icon: 'pi pi-bolt',
            routerLinkActiveOptions: {exact: true},
          },
          {
            routerLink: 'thermal-dashboard',
            label: 'Thermal Dashboard',
            visible: this.isFeatureAllowed('thermal_dashboard'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            icon: 'pi pi-eye',
            routerLinkActiveOptions: {exact: true},
          },
          {
            label: 'Cases', routerLink: 'cases',
            visible: this.isFeatureAllowed('analysis'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            icon: 'pi pi-file',
          },
          {
            label: 'Notifications',
            icon: 'pi pi-bell',
            visible: this.isFeatureAllowed('notifications'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            routerLink: 'notifications/settings',
          },
          {
            label: 'Events',
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            icon: 'pi pi-cloud-upload',
            routerLinkActiveOptions: {exact: false},
            visible: this.isFeatureAllowed('events'),
            items: [
              {
                routerLink: 'events/overview',
                label: 'Overview',
                routerLinkActiveOptions: {exact: true},
              },
              {
                routerLink: 'events/motion',
                label: 'Motion',
                routerLinkActiveOptions: {exact: true},
              },
              {
                routerLink: 'events/timer',
                label: 'Timer',
                routerLinkActiveOptions: {exact: true},
              },
              {
                routerLink: 'events/ptz-round',
                label: 'PTZ Round',
                routerLinkActiveOptions: {exact: true},
              },
              // {
              //   routerLink: 'events/anpr',
              //   label: 'ANPR',
              //   routerLinkActiveOptions: {exact: true},
              // },
              // {
              //   routerLink: 'events/stitch',
              //   label: 'Stitch',
              //   routerLinkActiveOptions: {exact: true},
              // },
            ],
          },
          {
            label: 'Downloads',
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            icon: 'pi pi-download',
            visible: this.isFeatureAllowed('downloads'),
            items: [
              {routerLink: 'downloads/camera-videos', label: 'Camera Videos'},
              {routerLink: 'downloads/timelapses', label: 'Timelapses'},
              {
                routerLink: 'downloads/image-collections',
                label: 'Image Collections',
              },
              {
                routerLink: 'downloads/scheduled-tasks',
                label: 'Scheduled Tasks',
              },
            ],
          },
          {
            label: 'Configurations',
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            icon: 'pi pi-box',
            visible: this.isFeatureAllowed('configurations'),
            items: [
              {routerLink: 'configurations/overview', label: 'Overview'},
              {routerLink: 'configurations/configurations', label: 'Configurations'},
              {routerLink: 'configurations/triggers', label: 'Triggers'},
              // {routerLink: 'configurations/multi-stitch', label: 'Multi Stitch'},
              // {routerLink: 'configurations/trigger-groups', label: 'Trigger Groups'}
            ],
          },
          {
            label: 'Accessories',
            icon: 'pi pi-link',
            visible: this.isFeatureAllowed('accessories'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            items: [
              {routerLink: 'accessories/lamps', label: 'Lamps'},
              {routerLink: 'accessories/power-usage', label: 'Power Usage'},
            ],
          },
          {
            label: 'Organization',
            icon: 'pi pi-sitemap',
            visible: this.isFeatureAllowed('organization'),
            command: (event: any) => {
              this.itemClicked(event.originalEvent.target);
            },
            items: [
              {routerLink: 'organization/users', label: 'Users'},
              {routerLink: 'organization/info', label: 'Info', visible: this.userInfo?.type.includes('admin')},
            ],
          },
          {
            label: 'Support',
            icon: 'pi pi-question-circle',
            items: [
              {label: 'Contact support', command: () => location.href = 'mailto:support@viewandintegrate.be'},
              {routerLink: 'help', label: 'Documentation'},
            ],
          },
        ];
      });
    });
  }

  itemClicked(target: any) {
    //Bij klik op child span element -> zet target naar parent (link)
    if (target.nodeName === 'SPAN') target = target.parentElement;

    if (this.prevTarget) this.prevTarget.classList.remove('active');

    target.classList.add('active');
    this.prevTarget = target;
  }

  isFeatureAllowed(feature: string): boolean {
    return this.allowedFeatures.includes(feature);
  }
}

//Menu items
