<footer class="navbar fixed-bottom">
  <div class="container justify-content-center">
    <p>
      &copy; {{ year }}
      <a href="https://www.viewandintegrate.be" target="_blank"
      >View and Integrate</a
      >
    </p>
  </div>
</footer>
