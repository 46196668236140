/**
 * @license
 * Copyright View and Integrate BVBA. All Rights Reserved.
 *
 * @author
 * Eric Lembregts <eric@viewandintegrate.be>
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BaseCalls} from '../../../objects/BaseCalls';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AuthService extends BaseCalls {
  readonly secure = location.protocol === 'https:';

  /**
   * Sets the secure flag if we are using a https session to authenticate
   *
   * @param httpClient
   * @param router
   */
  constructor(httpClient: HttpClient, private router: Router) {
    super(httpClient);
  }

  /**
   * Logs in our user against our backend services, redirects to dashboard in case of success.
   *
   * @param username
   * @param password
   * @param error_func
   */
  public login(username: any, password: any, error_func: any): void {
    let formData: FormData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    this.doPostCall_legacy(
      this.baseUrl + '/login/access-token',
      formData
    ).subscribe((response: any) => {
      if (response.state === 'error') {
        return error_func(response.message);
      }
      localStorage.setItem('token', response.access_token);
      localStorage.setItem('username', response.username);

      this.router.navigate(['/']);
    });
  }

  /**
   * Destroys any cookies with our tokens.
   */
  public logout(doBackend = true): void {
    if (doBackend) {
      this.doPostCall(this.baseUrl + '/logout', {}).subscribe();
    }

    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /**
   * Destroys any cookies and set the logged_in_username cookie for logging in to the application again.
   * to the main login window, to be discussed.
   */
  public lock(): void {
    localStorage.removeItem('token');

    let logged_in_username = localStorage.getItem('username');
    if (logged_in_username)
      //Probably better to throw exception when not found?
      localStorage.setItem('locked_username', logged_in_username);
  }

  /**
   * Unlock the application, by performing a login activity with the password and stored username.
   *
   * @param password
   */
  public unlock(password: any): void {
    // let username = localStorage.getItem('locked_username');
    localStorage.removeItem('locked_username');

    /*if (!this.login(username, password, function(error_msg) {})){
            localStorage.setItem('locked_username', username, 7, '/', '', this.secure);
        };*/
  }

  /**
   * Short stub function to see if our token is there, this does not actually validate that it is a valid token, but not having
   * a valid token will trigger a 403 response to any API call and thus redirecting our user to the login page.
   *
   * @returns {boolean}
   */
  public isAuthenticated(): boolean {
    return localStorage.getItem('token') !== null;
  }

  /**
   * Short stub function to see if our session is in a locked state.
   *
   * @returns {boolean}
   */
  public isLocked(): boolean {
    return localStorage.getItem('locked_username') !== '';
  }

  getUserInfo() {
    return this.doCall(this.baseUrl + '/frontend/get_user_info');
  }

  getAllowedFeatures() {
    return this.call('organizationallowedfeatures/');
  }

  postPasswordReset(email: string) {
    return this.doPostCall(this.baseUrl + '/password-recovery/' + email, {});
  }

  postNewPassword(password: string, token: string) {
    return this.doPostCall(this.baseUrl + '/reset-password/', {
      token: token,
      new_password: password,
    });
  }

  postOtp(otp: string) {
    this.doPostCall(this.baseUrl + '/login/test_otp', {
      otp: otp,
    }).subscribe((response: any) => {
      if (response.jwt) {
        localStorage.setItem('token', response.jwt);
        this.router.navigate(['/']);
      }
    });
  }

  getOtpUrl() {
    return this.doCall(this.baseUrl + '/login/request_otp_secret');
  }

  verifyOtp(otp: string, secret: string) {
    return this.doPostCall(this.baseUrl + '/login/enable_otp', {
      otp_secret: secret,
      otp_code: otp,
    });
  }

  removeOtp(otp: string) {
    return this.doPostCall(this.baseUrl + '/login/disable_otp', {otp_code: otp});
  }
}
