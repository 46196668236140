import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isLoading = new Subject<boolean>();

  isLoading$ = this.isLoading.asObservable();

  constructor() {}

  changeIsLoading(bool: boolean) {
    this.isLoading.next(bool);
  }
}
