import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.scss'
})
export class OtpComponent implements OnInit {
  otp: string = '';

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {

  }

  verifyOtp() {
    if (this.otp.length !== 6) {
      return;
    }
    this.authService.postOtp(this.otp);
  }
}
