import {inject, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {MainLayoutComponent} from './layouts/main/main-layout.component';
import {AuthGuardService} from './shared/services/auth.guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        component: MainLayoutComponent,
        canActivate: [() => inject(AuthGuardService).canActivate()],
        loadChildren: () =>
          import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: '',
        component: AuthLayoutComponent,
        loadChildren: () =>
          import('./public/public.module').then((m) => m.PublicModule),
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
